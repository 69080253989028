// ==============My variables============= //
$header-background: #424242;
$header-text: #6dcf76 !default;
$header-text-number: #f0f4fa !default;
$header-shadow: 0px 2px 4px rgba(30, 31, 41, 0.15);

$text-license: #a4aebd !default;
$text-color-white: #f0f4fa !default;

$info-background: linear-gradient(115.66deg, #40ad49 23.1%, #53be5c 117.3%);
$form-header: linear-gradient(115.66deg, #40ad49 23.1%, #53be5c 117.3%);

$footer-background: #424242;
$footer-text: #f0f4fa;

$gray100: #e4ebf6 !default; //серый для плашек
