@import '../../variables';
.content {
  width: 700px;
  background: #f0f4fa;
  border-radius: 20px;
  .contentHeader {
    background: #53be5c;
    box-shadow: 0px 5px 15px rgba(93, 99, 106, 0.2);
    border-radius: 20px 20px 0 0;
    width: 100%;
    h1 {
      color: #f0f4fa;
      padding: 33px 36px 33px;
      margin: 0;
    }
    @media (max-width: 391px) {
      border-radius: 0px;
    }
  }
  .contentMain {
    border-radius: 20px;
    margin: auto;
    width: 80%;
    padding-bottom: 50px;
    h2 {
      color: #8e96a3;
      margin: auto;
      padding: 40px 0 30px;
    }
    h3 {
      color: #8e96a3;
    }
    p {
      color: #a4aebd;
    }
    span {
      color: #53be5c;
    }
    .codeField {
      background: #e4ebf6;
      border-radius: 20px 20px 0 0;
      display: flex;
      margin: auto;
      padding: 22px 32px 0px;
      input {
        width: 60%;
        height: 50px;
        border-radius: 10px;
        box-sizing: border-box;
        border: 1px solid #545b69;

        text-align: center;
        font-family: 'Solomon Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 110%;
        color: #545b69;
      }
      input::placeholder {
        font-size: 30px;
        text-align: center;
      }
      .activeField {
        .statusSpan {
          padding-top: 10px;
          margin: 0;
          display: flex;
          flex-direction: column;
          width: 40%;
          // label {
          //   margin-left: 5px;
          // }
          .statusSpanOk,
          .statusSpanErrorBack,
          .statusSpanErrorCode {
            display: none;
          }
        }
      }
      p {
        padding-top: 50px;
      }
    }
    @media (max-width: 391px) {
      h2 {
        font-size: 18px;
      }
      .codeField {
        img {
          display: none;
        }
      }
    }
  }
  .footerField {
    background: #53be5c;
    margin: auto;
    padding: 10px 0 10px;
    border-radius: 0 0 20px 20px;
    span {
      margin-left: 5%;
      color: #f0f4fa;
    }
  }
}
