.content {
  .hide {
    display: none;
  }
  width: 300px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1199px) {
    margin-top: 30px;
    width: 700px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 22px 20px;
    background: linear-gradient(115.66deg, #40ad49 23.1%, #53be5c 117.3%);
    border-radius: 15px;
    margin-bottom: 20px;
    .text {
      width: 169px;
      text-align: left;
      font-family: 'Solomon Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 110%;
      color: #f0f4fa;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    padding: 22px 20px;
    border-radius: 15px;
    background: linear-gradient(115.66deg, #40ad49 23.1%, #53be5c 117.3%);
    margin-bottom: 20px;
    position: relative;
    .infoIcon {
      position: absolute;
      top: -15px;
      right: -15px;
      width: 40px;
      height: 40px;
    }
    .text {
      text-align: left;
      font-family: 'Solomon Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 110%;
      color: #f0f4fa;
    }
    .license {
      background: #f0f4fa;
      border-radius: 10px;
      padding: 15px 0;
      margin: 20px 0 30px;
      font-family: 'Solomon Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 110%;
      color: #a4aebd;
      text-align: center;
    }
    .protect {
      display: flex;
      justify-content: space-between;
      .text {
        width: 213px;
        font-weight: 700;
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: column;
    padding: 22px 20px;
    border-radius: 15px;
    background: linear-gradient(115.66deg, #40ad49 23.1%, #53be5c 117.3%);
    .title {
      font-family: 'Solomon Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 110%;
      color: #f0f4fa;
    }
    .link {
      cursor: pointer;
      font-family: 'Solomon Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 110%;
      text-decoration-line: underline;
      color: #f0f4fa;
      opacity: 0.5;
    }
  }
}
