@import '../../variables';

.header {
  background: $header-background;
  box-shadow: $header-shadow;
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 100px;
  @media (max-width: 768px) {
    margin: 0;
  }

  .login {
    display: none;
    @media (max-width: 525px) {
      display: flex;
      justify-content: flex-end;
      background: linear-gradient(115.66deg, #40ad49 23.1%, #53be5c 117.3%);
      width: 15%;
      height: 100%;
      .enterIcon {
        margin: auto;
        width: 50%;
        height: 70%;
      }
    }
    @media (max-width: 768px) {
      background: linear-gradient(115.66deg, #40ad49 23.1%, #53be5c 117.3%);
      display: flex;
      width: 64px;
      height: 44px;
      .enterIcon {
        margin: auto;
      }
    }
  }
  .logo {
    padding-left: 10%;
    @media (max-width: 525px) {
      display: flex;
      padding-left: 20px;
      margin: 0;
    }
  }

  .aboutInfo {
    padding-right: 10%;
    div {
      display: flex;
    }
    .info {
      justify-content: flex-end;
      font-size: 11px;
      color: $header-text;
      font-weight: 600;
      line-height: 110%;
      font-style: normal;
      font-family: 'Solomon Sans';
    }
    .number {
      font-size: 23px;
      color: $header-text-number;
      font-weight: 600;
      line-height: 110%;
      font-style: normal;
      font-family: 'Solomon Sans';
    }
    @media (max-width: 525px) {
      display: none;
    }
  }
}
