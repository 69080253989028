.content {
  .hide {
    display: none;
  }
  width: 700px;
  border-radius: 20px;
  box-shadow: 0px 5px 15px rgba(93, 99, 106, 0.2);
  background: #f0f4fa;
  @media (max-width: 768px) {
    width: 100%;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(115.66deg, #40ad49 23.1%, #53be5c 117.3%);
    border-radius: 20px 20px 0 0;
    padding: 21px 70px;
    @media (max-width: 768px) {
      padding: 15px 26px;
      border-radius: 0;
    }
    .title {
      width: 277px;
      img {
        width: 52px;
        height: 52px;
        margin-right: 10px;
      }
      display: flex;
      align-items: center;
      font-family: 'Solomon Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 110%;
      color: #f0f4fa;
      @media (max-width: 768px) {
        padding: 0;
        font-size: 21px;
      }
    }
    .disable {
      width: 94px;
      justify-content: space-between;
      display: flex;
      align-items: center;
      img {
        width: 42px;
        height: 42px;
      }
    }
  }
  .progress {
    width: 100%;
    height: 24px;
    background: #545b69;
    display: flex;
    align-items: center;
    .colored {
      display: flex;
      align-items: center;
      background: #53be5c;
      width: 55%;
      height: 24px;
      // padding: 5px 0;
      border-radius: 0 5px 5px 0;
      @media (max-width: 768px) {
      }
      .text {
        padding: 0 76px;
        font-family: 'Solomon Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 110%;
        color: #f0f4fa;
        @media (max-width: 768px) {
          font-size: 10px;
          padding: 0 20px;
        }
      }
    }
  }
  form {
    padding: 40px 70px 30px;
    @media (max-width: 768px) {
      padding: 15px 20px 30px;
    }
    .title {
      margin-bottom: 16px;
      font-family: 'Solomon Sans Black';
      font-style: normal;
      font-weight: 900;
      font-size: 19px;
      line-height: 110%;
      color: #545b69;
    }
    .input {
      padding: 0;
      margin-bottom: 15px;
      border: 1px solid #a4aebd;
      border-radius: 10px;
      width: 100%;
      height: 50px;
      text-indent: 14px;
      font-family: 'Solomon Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 110%;
      color: #545b69;
      &::placeholder {
        color: #a4aebd;
      }
      &:focus {
        outline: 1px solid #545b69;
      }
      @media (max-width: 768px) {
        height: 44px;
        font-size: 15px;
      }
    }
    .gender {
      .genderTitle {
        margin-right: 40px;
      }
      .genderCheckbox {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      font-family: 'Solomon Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 110%;
      color: #a4aebd;

      input[type='checkbox'] {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .passport {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .inputPassport {
        padding: 0;
        border: 1px solid #a4aebd;
        border-radius: 10px;
        width: 270px;
        height: 50px;
        text-indent: 14px;
        font-family: 'Solomon Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 110%;
        color: #545b69;
        &::placeholder {
          color: #a4aebd;
        }
        &:focus {
          outline: 1px solid #545b69;
        }
        @media (max-width: 768px) {
          height: 44px;
          font-size: 15px;
          width: 100%;
        }
      }
    }
    .showAddress {
      .showAddressCheckbox {
        display: flex;
        align-items: center;
      }
      margin-bottom: 18px;
      font-family: 'Solomon Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 110%;
      display: flex;
      align-items: center;
      color: #a4aebd;
      input[type='checkbox'] {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .btn {
      button {
        padding: 15px 129px;
        background: #40ad49;
        border: 0;
        border-radius: 5px;

        font-family: 'Solomon Sans Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 120%;
        color: #f0f4fa;
        @media (max-width: 768px) {
          margin-top: 20px;
          padding: 15px 109px;
        }
      }
      .btnBack {
        text-decoration: underline;
        margin-top: 12px;
        font-family: 'Solomon Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 110%;
        display: flex;
        align-items: center;
        color: #a4aebd;
      }
    }
  }
}
