.content {
  width: 700px;
  border-radius: 20px;
  box-shadow: 0px 5px 15px rgba(93, 99, 106, 0.2);
  @media (max-width: 768px) {
    width: 100%;
  }
  .header {
    background: linear-gradient(115.66deg, #40ad49 23.1%, #53be5c 117.3%);
    border-radius: 20px 20px 0 0;
    padding: 22px 0;
    @media (max-width: 768px) {
      padding: 15px 26px;
      border-radius: 0;
    }
    .title {
      width: 293px;
      padding: 0 76px;
      font-family: 'Solomon Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 110%;
      color: #f0f4fa;
      @media (max-width: 768px) {
        padding: 0;
        font-size: 21px;
      }
      span {
        font-weight: 700;
      }
    }
  }
  .progress {
    padding: 5px 0;
    width: 100%;
    background: #545b69;
    @media (max-width: 768px) {
      padding: 5px 26px;
    }
    .progressFilled {
      padding: 0 76px;
      font-family: 'Solomon Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 110%;
      color: #f0f4fa;
      @media (max-width: 768px) {
        padding: 0;
        font-size: 10px;
      }
    }
  }
  form {
    .active {
      position: relative;
      .errors {
        border: 1px solid red;
      }
      .hide {
        display: none;
      }
      .errorLabel {
        position: absolute;
        top: 0;
        left: 0;
        color: red;
      }
      .successLabel {
        position: absolute;
        top: 0;
        left: 0;
        color: green;
      }
      .focusLabel {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    display: flex;
    flex-direction: column;
    padding: 40px 70px 30px;
    @media (max-width: 768px) {
      padding: 15px 20px 30px;
    }
    input {
      padding: 0;
      margin-bottom: 15px;
      border: 1px solid #a4aebd;
      border-radius: 10px;
      width: 100%;
      height: 50px;
      text-indent: 14px;
      font-family: 'Solomon Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 110%;
      color: #545b69;
      &::placeholder {
        color: #a4aebd;
      }
      &:focus {
        outline: 1px solid #545b69;
      }
      @media (max-width: 768px) {
        height: 44px;
        font-size: 15px;
      }
    }
    .checkboxField {
      width: 95%;
      margin: auto;
      @media (max-width: 768px) {
        width: 100%;
      }
      .soloCheckbox {
        padding: 19px 18px;
        background: #e4ebf6;
      }
      .active {
        margin-bottom: 11px;
        .hiddenCheckbox {
          display: none;
        }
        .checkboxContainer {
          position: relative;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        .customCheckbox {
          width: 22px;
          height: 22px;
          margin-right: 10px;
          border: 1px solid #a4aebd;
          border-radius: 4px;
          display: inline-block;
          background-color: transparent;
          transition: background-color 0.1s;
        }
        .customCheckbox::after {
          content: '';
          position: absolute;
          top: 9px;
          left: 11px;
          width: 9px;
          height: 7px;
          border: 2px solid #f0f4fa;
          border-top: none;
          border-right: none;
          background: #53be5c;
          transform: translate(-50%, -50%) rotate(-45deg);
          opacity: 0;
        }
        .checked {
          background-color: #53be5c;
          border: 1px solid #53be5c;
        }
        .checked::after {
          opacity: 1;
        }
        .customCheckboxLabel {
          font-family: 'Solomon Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 110%;
          color: #8e96a3;
        }
        .activeCheckboxLabel {
          color: #545b69;
        }
      }
    }
    .btn {
      button {
        padding: 15px 129px;
        background: #40ad49;
        border: 0;
        border-radius: 5px;

        font-family: 'Solomon Sans Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 120%;
        color: #f0f4fa;
        @media (max-width: 768px) {
          margin-top: 20px;
          padding: 15px 109px;
        }
      }
    }
  }
}
