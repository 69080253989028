@import '../../variables';
.footer {
  height: 58px;
  // padding: 31px 0 19px;
  width: 100%;
  background-color: $footer-background;
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-top: 100px;
  @media (max-width: 768px) {
    margin: 0;
  }

  // @media (max-width: 900px) {
  //   margin-top: 100px;
  // }
  // @media (max-width: 550px) {
  //   display: block;
  //   margin: auto;
  //   padding: 20px 22px 20px;
  //   background: #f0f4fa;
  //   border-radius: 10px 0 0 0;
  // }
  // @media (max-width: 391px) {
  //   display: block;
  //   margin: auto;
  //   padding: 20px 22px 20px;
  //   background: #f0f4fa;
  //   border-radius: 10px 0 0 0;
  // }

  img {
    padding-left: 10%;
    @media (max-width: 391px) {
      display: none;
      padding: 0;
      margin: 0;
    }
    @media (max-width: 550px) {
      display: none;
      padding: 0;
      margin: 0;
    }
  }

  .aboutInfo {
    padding-right: 10%;
    text-align: right;
    max-width: 40%;
    color: $footer-text;
    font-size: 11px;
    @media (max-width: 391px) {
      display: flex;
      color: #8e96a3;
      max-width: 100%;
      text-align: center;
      margin: auto;
    }
    @media (max-width: 550px) {
      display: flex;
      color: #8e96a3;
      max-width: 100%;
      text-align: center;
      margin: auto;
    }
  }
}
