@import '../../variables';
.wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: auto;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #e6eaf0;
  .mainPos {
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: 1030px;
    @media (max-width: 1199px) {
      width: 700px;
      margin: auto;
      flex-direction: column-reverse;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
